import { firestore } from "../../firebase/firestore"
// import { firebaseAuth } from '../../firebase/firebaseAuth'

export const getLead = (email) => {
    return firestore.collection('leads').doc(email).get()
};

export const updateLead = (email, data) => {
    // console.log('lead_email: ', email)
    // console.log('lead_data: ', data)
    const ref = firestore.collection('leads').doc(email)
    data.updated_at = new Date()
    return ref.update(data)
        .then(() => {
            return ref.get()
        })
        .catch(error => {
            console.error('Erro ao atualizar usuário')
            return error
        })
}

export const storeLead = (email, data) => {
    // console.log('lead_email: ', email)
    // console.log('lead_data: ', data)
    const ref = firestore.collection('leads').doc(email)
    data.updated_at = new Date()
    return ref
        .get()
        .then(lead => {
            if(lead.exists) {
                return ref.update(data)
                    .then(() => {
                        return ref.get()
                    })
                    .catch(error => {
                        console.error('Erro ao atualizar usuário')
                        return error
                    })
            } else {
                data.created_at = new Date()
                return ref.set(data)
                    .then(() => {
                        return ref.get()
                    })
                    .catch(error => {
                        console.error('Erro ao atualizar usuário')
                        return error
                    })
            }
        })
        .catch(error => {
            console.error('Erro ao atualizar usuário')
            return error
        })
}