<template>
  <div style="background-color: #FFF; min-height: 100vh;">
    <v-container fluid v-if="loading_open">
      <v-layout align-center justify-center row fill-height>
        <v-progress-circular
          :size="70"
          :width="7"
          color="success"
          indeterminate
        ></v-progress-circular>
      </v-layout>
    </v-container>
    <div id="app"  v-if="!loading_open">
      <!-- <ul id="menu" style="background-color:#000"></ul> -->
      <v-container fluid grid-list-md fill-height>
        <v-layout row wrap>
          <!-- Web -->
          <v-flex xs12 sm12 md6 class="hidden-sm-and-down align-center justify-center" style="text-align: center; background: #efefef; padding: 3%; margin:0; min-height: 100vh; color:#000">
            <!-- Right WEB -->
            <cpnHeaderWeb class="hidden-sm-and-down"/>
            <v-flex xs12 sm12 md12>
              <div class="header hidden-sm-and-down">
                <h2 class="pb-4">Nosso sol pode salvar o mundo e suas finanças</h2>
                <v-btn flat small @click.prevent="showHowWorks" text-start color="black">Como funciona</v-btn>
                <!-- <cpnFaq /> -->
                <div class="align-center">
                  <iframe width="100%" class="video" height="195" src="https://www.youtube.com/embed/aXmcmbiNcjg?controls=2" title="Com o funciona a Enercred" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
                <div class="subtitle pt-4">Energia Limpa por assinatura</div>
                <div class="subtitle pt-4">Sem obras + Prático + Digital</div>
                <!-- <v-flex xs12 sm12 md12 style="text-align: right" class="hidden-sm-and-down">
                  <cpnWhatsappButton :whatsapp_text="{text: '', subtext: ''}"/>
                </v-flex> -->
              </div>
            </v-flex>
          </v-flex>
          <!-- Mobile -->
          <v-flex xs12 sm12 md6 class="hidden-md-and-up" style="background: #FFF; padding: 3%;">
            <!-- Header Mobile -->
            <cpnHeaderMobile class="hidden-md-and-up" hasDuvidas="false"/>
            <v-flex xs12 sm12 md12 style="background: #FFF; padding: 3%;">
              <div class="header hidden-md-and-up">
                <h3 style="color:#2E3CAE;" class="pb-2">Nosso sol pode salvar o mundo e suas finanças</h3>
                <!-- <h4>Energia Limpa por assinatura</h4> -->
                <v-btn flat small color="primary" @click.prevent="showHowWorks">Como funciona</v-btn>
                <!-- <div>Até 15% de economia na sua conta de energia</div> -->
                <div class="align-center">
                  <iframe width="100%" class="video" height="195" src="https://www.youtube.com/embed/aXmcmbiNcjg?&controls=2" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
                <cpnSteps :steps="{steps: 5, e1: 1}"/>
              </div>
            </v-flex>
          </v-flex>
          <v-flex xs12 sm12 md6 style="text-align: center;" class="align-center">
            <div class="form_cadastro">
              <div style="padding-bottom: 15px;">
                <cpnSteps class="hidden-sm-and-down" :steps="{steps: 5, e1: 1}"/>
                <!-- <div
                  class="display-1 hidden-sm-and-down"
                  style="padding-bottom: 25px; color: #808080; padding-top: 40px"
                >ECONOMIZAR</div> -->
              </div>
              <v-form ref="form" v-model="valid" lazy-validation>
                <h4 class="font-weight-black pb-4 text-center">Simule sua economia</h4>
                <v-radio-group
                  v-model="person_type"
                  style="padding: 3px 0 0 3px;"
                  :rules="[
                    v => !!v || 'Por favor, selecione a concessionária!'
                  ]"
                  row
                >
                  <v-radio :key="1" :label="`Para sua casa`" value="pf" style="padding-right: 10px"></v-radio>
                  <v-radio :key="2" :label="`Para sua empresa`" value="pj"></v-radio>
                </v-radio-group>
                <v-text-field
                  v-model="email"
                  :rules="emailRules"
                  label="*E-mail"
                  prepend-inner-icon="email"
                  name="email"
                  type="email"
                  required
                  outline
                  autofocus
                ></v-text-field>
                <v-text-field
                  v-model="name"
                  name="name"
                  :rules="nameRules"
                  label="*Nome Completo"
                  prepend-inner-icon="person"
                  required
                  outline
                ></v-text-field>
                <v-text-field
                  type="tel"
                  v-model="phone"
                  :rules="phoneRules"
                  prepend-inner-icon="phone"
                  label="*Celular"
                  return-masked-value
                  mask="(##)#####-####"
                  required
                  outline
                ></v-text-field>
                <v-checkbox
                  v-model="whatsapp_optin"
                  label="Celular com WhatsApp. Permito receber mensagens sobre energia limpa."
                  style="color: red"
                >
                  <template v-slot:label>
                    <div style="font-size:12px">
                      Celular com WhatsApp. Aceito receber mensagens sobre energia limpa.
                    </div>
                  </template>
                </v-checkbox>
                <v-text-field
                  v-model="zipcode"
                  name="zipcode"
                  :rules="[
                    v => !!v || 'Por favor, informe seu CEP!'
                  ]"
                  label="*CEP"
                  mask="#####-###"
                  prepend-inner-icon="place"
                  required
                  outline
                  @change="buscaCep($event)"
                ></v-text-field>
                <header
                  style="text-align: left;padding-bottom: 4px"
                >Quanto você gasta de luz por mês?</header>

                <v-text-field
                  type="tel"
                  v-model="average_consumption"
                  prefix="R$"
                  value="0"
                  :rules="[
                    v => !!v || 'Por favor, informe sua média de gastos!',
                    v => v > 199 || 'Você deve ter uma média de gastos superior a R$200 para assinar o serviço'
                  ]"
                  prepend-inner-icon="money"
                  return-masked-value
                  mask="########"
                  suffix=",00"
                  required
                  outline
                ></v-text-field>
                <header style="text-align: left;">Qual a sua concessionária?</header>

                <v-radio-group
                  v-model="concessionaria"
                  style="padding: 3px 0 0 3px;"
                  :rules="[
                    v => !!v || 'Por favor, selecione a concessionária!'
                  ]"
                  row
                >
                  <v-radio :key="1" :label="`Cemig`" value="cemig" style="padding-right: 10px"></v-radio>
                  <v-radio :key="2" :label="`Outro`" value="outro"></v-radio>
                </v-radio-group>
                <v-flex xs12 sm12 md12 class="text-xs-left">
                  <small>O dados serão utilizados exlusivamente para te oferecer nossas soluções de economia e energia limpa.</small>
                </v-flex>
                <v-flex xs12 sm12 md12 style="text-align: right">
                  <v-btn
                    :disabled="!valid"
                    color="success"
                    @click.prevent="validate"
                    block
                    large
                    round
                    :loading="loading"
                    type="submit"
                  >SIMULAR</v-btn>
                </v-flex>
                <!-- <v-flex xs12 sm12 md12 style="text-align: right" class="hidden-md-and-up">
                  <cpnWhatsappButton :whatsapp_text="{text: '', subtext: ''}"/>
                </v-flex> -->
              </v-form>
              <!-- <v-progress-circular v-if="loading" :size="50" color="teal" indeterminate></v-progress-circular> -->
            </div>
          </v-flex>
          <v-flex class="hidden-md-and-up" style="background:#00A8BB; padding: 30px 30px 70px 30px; align-itens:center">
            <div style="padding: 5% 5% 5% 5%; font-weight: 800" class="--text">
              <h3 style="color:#FFF">Faça parte dessa comunidade que economiza e ajuda o planeta</h3>
            </div>
            <iframe width="100%" class="video" height="195" src="https://www.youtube.com/embed/0PVzZVgoolM?enablejsapi=1&amp;origin=https%3A%2F%2Fenercred.com.br" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="" data-gtm-yt-inspected-30051236_3="true" id="453571096" data-gtm-yt-inspected-1_25="true"></iframe>
            <iframe width="100%" class="video" height="195" src="https://www.youtube.com/embed/aTF-JUDkk08?enablejsapi=1&amp;origin=https%3A%2F%2Fenercred.com.br" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="" data-gtm-yt-inspected-30051236_3="true" id="481339508" data-gtm-yt-inspected-1_25="true"></iframe>
            <iframe width="100%" class="video" height="195" src="https://www.youtube.com/embed/n_o0frzSyaE?enablejsapi=1&amp;origin=https%3A%2F%2Fenercred.com.br" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="" data-gtm-yt-inspected-30051236_3="true" id="298303397" data-gtm-yt-inspected-1_25="true"></iframe>
          </v-flex>
          <!-- <v-flex class="hidden-md-and-up" style="background:#efefef">
            <div style="padding: 5% 5% 5% 5%; font-weight: 800">Dúvidas?</div>
            <div class="title" id= "faq">
                <cpnFaq />
            </div>
          </v-flex> -->
        </v-layout>
      </v-container>
      <v-dialog v-model="faq_dialog">
        <cpnFaq />
      </v-dialog>
    </div>
    <how-works/>
  </div>
</template>
<script>
import { getLead, storeLead } from '@/services/firebase/lead'
import { enviarEmail } from "@/services/sendmail"
import { getCEP } from '@/services/getCep'
import { saveAnalytics } from "@/services/save_analytics"
// import { SET_CONVERSION } from "../../graphql/rdstation"
import Faq from "../../components/faq/Faq"
import HeaderWeb from "../../components/web/Header"
import HeaderMobile from "../../components/mobile/Header"
import Steps from '../../components/core/Steps'
// import WhatsappButton from '../../components/core/WhatsappButton'
import { formatPhone, unformatPhone } from '@/utils/utils'
import { eventBus } from "../../main"
import HowWorks from '../../components/HowWorks.vue'

export default {
  components: {
    cpnFaq: Faq,
    cpnHeaderWeb: HeaderWeb,
    cpnHeaderMobile: HeaderMobile,
    cpnSteps: Steps,
    HowWorks
    // cpnWhatsappButton: WhatsappButton,
  },
  data() {
    return {
      // openModal: false,
      valid: false,
      concessionarias: ["Cemig", "Outro"],
      email: "",
      emailRules: [v => !!v || "Por favor, informe o seu email", v => /.+@.+/.test(v) || "E-mail precisa ser válido"],
      name: "",
      nameRules: [
        v => !!v || "Por favor, informe o seu nome",
        v => v.length >= 10 || "Por favor, preencha seu nome completo."
      ],
      zipcode: "",
      concessionaria: "",
      phone: "",
      phoneRules: [
        v => !!v || "Por favor, informe o seu celular",
        v => v.length > 10 || "Ops. Parece que esse não é um número de telefone"
      ],
      promo_code: this.$route.query.referral_code || "",
      average_consumption: "",
      whatsapp_optin: true,
      step: 0,
      customer_id: "",
      show1: false,
      rules: {
        required: value => !!value || "Obrigatório.",
        min: v => v.length >= 8 || "Min 8 caracteres",
        emailMatch: () => "E-mail ou senha incorretos"
      },
      interval: {},
      progress: 0,
      address: null,
      loading: false,
      loading_open: true,
      isProd: false,
      utm_source: "",
      utm_medium: "",
      utm_campaign: "",
      utm_content: "",
      utm_term: "",
      utm_transaction: '',
      lead: {},
      cpf: "",
      titular: "",
      faq_dialog: false,
      person_type: 'pf'
    }
  },
  beforeDestroy() {
    this.loading = false
  },
  created() {
    this.loading_open = true
    this.isProd = process.env.NODE_ENV === "production"
    this.getUTM()
    if (this.isProd) {
      const analytics = {
        eventCategory: "onboard",
        eventAction: "VIEWED_ONBOARD",
        eventLabel: "VIEWED_ONBOARD",
        value: "",
        utm_source: this.utm_source,
        utm_medium: this.utm_medium,
        utm_campaign: this.utm_campaign,
        utm_content: this.utm_content,
        utm_term: this.utm_term,
      }
      
      if(this.$route.query.referral_code) {
        analytics.referral_code = {
          value: 30,
          action: 'REFERRAL_VIEW',
          category: 'REFERRAL',
          indicated: '',
          indicator: this.promo_code
        }
      }
      saveAnalytics(analytics)
    }
    if(this.$route.query.email) {
      const email = this.$route.query.email
      getLead(email)
        .then(lead => {
          this.setLead(lead.data.lead)
          this.loading_open = false
        })
        .catch(() => {
          console.error('Email não encontrado: ', email)
          this.loading_open = false
        })
    } else {
      this.lead = JSON.parse(localStorage.getItem("lead"))
      if(this.lead && this.lead.email) {
        this.setLead(this.lead)
      }
      this.loading_open = false
    }
  },
  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        this.signUp()
      }
    },
    async setLead(lead) {
      this.email = lead.email
      this.name = lead.name
      this.zipcode = lead.zipcode || ""
      this.utm_source = lead.utm_source || ""
      this.utm_medium = lead.utm_medium || ""
      this.utm_campaign = lead.utm_campaign || ""
      this.utm_content = lead.utm_content || ""
      this.utm_term = lead.utm_term || ""
      this.utm_transaction = lead.utm_transaction
      this.concessionaria = lead.concessionaria || ""
      this.phone = unformatPhone(lead.phone, '55') || ""
      this.promo_code = lead.promo_code || ""
      this.average_consumption = lead.average_consumption || ""
      this.step = lead.step || ""
      this.person_type = lead.person_type || "pf"
    },
    reset() {
      this.$refs.form.reset()
    },
    resetValidation() {
      this.$refs.form.resetValidation()
    },
    signUp: async function() {
      this.loading = true
      this.email = this.email.replace(" ", "")
      if (this.email.trim() === "") {
        console.error("Erro: Email é obrigatório")
        return 0
      }
      if(!this.address) {
        let address = {
          address: '',
          address_number: '',
          address_complement: '',
          district: '',
          postal_code: '',
          state: {
            initials: 'MG',
            name: 'Minas Gerais'
          },
          city: '',
          country: {
            initials: 'br',
            name: 'Brasil'
          },
        }
        this.address = address 
        if(this.zipcode) {
          let _address = await this.buscaCep(this.zipcode)
          // console.log('_address: ', _address)
          if(_address && _address.address) this.address = _address
        }
      }
      let lead = {
        // customer_id: this.email,
        email: this.email,
        name: this.name,
        zipcode: this.zipcode,
        concessionaria: this.concessionaria,
        phone: formatPhone(this.phone, '55'),
        promo_code: this.promo_code,
        average_consumption: this.average_consumption,
        step: 11,
        utm_source: this.utm_source,
        utm_medium: this.utm_medium,
        utm_campaign: this.utm_campaign,
        utm_content: this.utm_content,
        utm_term: this.utm_term,
        utm_transaction: this.utm_transaction,
        whatsapp_optin: this.whatsapp_optin,
        person_type: this.person_type
      }
      if(this.address) {
        lead.address = this.address
      }
      await storeLead(this.email, lead)
        .then(result => {
          if (result.data) {
            localStorage.setItem("lead", JSON.stringify(lead))
            this.$gtm.trackEvent({
              event: 'cadastro',
              category: "onboard",
              action: "click",
              label: "Cadastro na primeira pagina do onboard",
              value: this.average_consumption,
              noninteraction: false, // Optional
            });
            if (this.isProd) {
              // analytics
              const analytics = {
                eventCategory: "onboard",
                eventAction: "CREATE-NEW-LEAD",
                eventLabel: "CREATE-NEW-LEAD",
                value: this.average_consumption,
                utm_source: lead.utm_source,
                utm_medium: lead.utm_medium,
                utm_campaign: lead.utm_campaign,
                utm_content: lead.utm_content,
                utm_term: lead.utm_term,
                utm_transaction: lead.utm_transaction,
                person_type: lead.person_type
              }
              if(this.promo_code) {
                analytics.referral_code = {
                  value: this.average_consumption,
                  action: 'REFERRAL_LEAD',
                  category: 'REFERRAL',
                  indicated: lead.email,
                  indicator: this.promo_code
                }
              }
              saveAnalytics(analytics)

              // rd station
              // const conversion = {
              //   event_type: "CONVERSION",
              //   event_family: "CDP",
              //   payload: {
              //     conversion_identifier: "onboarding",
              //     name: lead.name,
              //     email: lead.email,
              //     mobile_phone: lead.phone ? lead.phone : "",
              //     traffic_source: lead.utm_source,
              //     traffic_medium: lead.utm_medium,
              //     traffic_campaign: lead.utm_campaign,
              //     tags: ["lead", "onboarding", lead.average_consumption * 1 > 200 ? "maior 200" : "ate 200"],
              //     available_for_mailing: true,
              //     cf_consumo_medio_mensal: lead.average_consumption,
              //     cf_grupo_consumo_medio_mensal: lead.average_consumption * 1 > 200 ? "maior 200" : "ate 200",
              //     cf_concessionaria: lead.concessionaria
              //   }
              // }
              // this.setRDConversion(conversion)
            }

            if (lead.concessionaria === "cemig") {
              // this.loading = false;
              this.$router.push("/assinatura/2")
            } else {
              this.loading = false
              const data = {
                email: lead.email,
                name: lead.name,
                dynamic_template_data: {
                  name: lead.name
                },
                template_id: process.env.VUE_APP_SENDGRID_TEMPLATE_LISTAESPERA
              }
              enviarEmail(data)
                .then(this.$router.replace("lista-espera"))
                .catch(error => {
                  console.error("Error: Erro ao enviar email", error)
                  this.$router.replace("lista-espera")
                })
            }
          }
        })
        .catch(err => {
          this.loading = false
          console.error("Error: ", err)
        })
    },
    getCookie(cname) {
      var name = cname + "="
      var ca = document.cookie.split(";")
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i]
        while (c.charAt(0) == " ") {
          c = c.substring(1)
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length)
        }
      }
      return ""
    },
    getUTM() {
      if (this.$route.query.utm_source) {
        this.utm_source = this.$route.query.utm_source
        localStorage.setItem("utm_source", this.utm_source)
        // } else if (window.$cookies.isKey('utm_source')) {
      } else if (this.getCookie("utm_source")) {
        this.utm_source = this.getCookie("utm_source")
        localStorage.setItem("utm_source", this.utm_source)
      } else if (localStorage.getItem("utm_source")) {
        this.utm_source = localStorage.getItem("utm_source")
      } else {
        this.utm_source = "direct"
      }

      if (this.$route.query.utm_medium) {
        this.utm_medium = this.$route.query.utm_medium
        localStorage.setItem("utm_medium", this.utm_medium)
        // } else if (window.$cookies.isKey('utm_medium')) {
      } else if (this.getCookie("utm_medium")) {
        this.utm_medium = this.getCookie("utm_medium")
        localStorage.setItem("utm_medium", this.utm_medium)
      } else if (localStorage.getItem("utm_medium")) {
        this.utm_medium = localStorage.getItem("utm_medium")
      } else {
        this.utm_medium = "direct"
      }

      if (this.$route.query.utm_campaign) {
        this.utm_campaign = this.$route.query.utm_campaign
        localStorage.setItem("utm_campaign", this.utm_campaign)
        // } else if (window.$cookies.isKey('utm_campaign')) {
      } else if (this.getCookie("utm_campaign")) {
        this.utm_campaign = this.getCookie("utm_campaign")
        localStorage.setItem("utm_campaign", this.utm_campaign)
      } else if (localStorage.getItem("utm_campaign")) {
        this.utm_campaign = localStorage.getItem("utm_campaign")
      } else {
        this.utm_campaign = "direct"
      }

      if (this.$route.query.xtra) {
        this.utm_transaction = this.$route.query.xtra
        localStorage.setItem("utm_transaction", this.utm_transaction)
        // } else if (window.$cookies.isKey('xtra')) {
      } else if (this.getCookie("utm_transaction")) {
        this.utm_transaction = this.getCookie("utm_transaction")
        localStorage.setItem("utm_transaction", this.utm_transaction)
      } else if (localStorage.getItem("utm_transaction")) {
        this.utm_transaction = localStorage.getItem("utm_transaction")
      } else {
        this.utm_transaction = "direct"
      }

      if (this.$route.query.utm_content) {
        this.utm_content = this.$route.query.utm_content
        localStorage.setItem("utm_content", this.utm_content)
        // } else if (window.$cookies.isKey('utm_content')) {
      } else if (this.getCookie("utm_content")) {
        this.utm_content = this.getCookie("utm_content")
        localStorage.setItem("utm_content", this.utm_content)
      } else if (localStorage.getItem("utm_content")) {
        this.utm_content = localStorage.getItem("utm_content")
      } else {
        this.utm_content = ""
      }

      if (this.$route.query.utm_term) {
        this.utm_term = this.$route.query.utm_term
        localStorage.setItem("utm_term", this.utm_term)
        // } else if (window.$cookies.isKey('utm_term')) {
      } else if (this.getCookie("utm_term")) {
        this.utm_term = this.getCookie("utm_term")
        localStorage.setItem("utm_term", this.utm_term)
      } else if (localStorage.getItem("utm_term")) {
        this.utm_term = localStorage.getItem("utm_term")
      } else {
        this.utm_term = ""
      }
      
    },
    // setRDConversion(conversion) {
    //   return this.$apollo.mutate({
    //     // Query
    //     mutation: SET_CONVERSION,
    //     // Parameters
    //     variables: {
    //       event_type: conversion.event_type,
    //       event_family: conversion.event_family,
    //       payload: conversion.payload
    //     }
    //   })
    // },
    buscaCep(cep) {
			if(cep && cep !== '') {
				this.loading_cep = true
				getCEP(cep)
					.then(async result => {
            let address = {
              address: result.data.logradouro || '',
              address_number: result.data.numero || '',
              address_complement: result.data.complement || '',
              district: result.data.bairro || '',
              postal_code: cep  || '',
              state: {
                initials: result.data.uf || 'MG',
                name: result.data.uf || 'Minas Gerais'
              },
              city: result.data.localidade || '',
              country: {
                initials: 'br',
                name: 'Brasil'
              },
            }
            this.address = address
            return address
						// this.snack('OK: ' + error, 'red', 'top')
					})
					.catch(error => {
						this.snack('Erro ao buscar o CEP informado. Erro: ' + error, 'red', top)
						this.loading_cep = false
            return error
					})
			} else return null
		},
    showHowWorks() {
     eventBus.$emit('open-dialog_howworks')
    }
  }
}
</script>

<style scoped>
@charset "ISO-8859-1";


/* Reset CSS
 * --------------------------------------- */
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
fieldset,
input,
textarea,
p,
blockquote,
th,
td {
  padding: 0;
  margin: 0;
}
a {
  text-decoration: none;
}

#app {
  /* font-family: "Rubik", sans-serif; */
  font-family: "Poppins", "Rubik", sans-serif;
}

fieldset,
img {
  border: 0;
}
ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
caption,
th {
  text-align: left;
}
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  font-size: 100%;
  margin: 0;
  padding: 0;
}
q:before,
q:after {
  content: "";
}
abbr,
acronym {
  border: 0;
}
* {
  -webkit-box-sizing: border-box; /* Safari<=5 Android<=3 */
  -moz-box-sizing: border-box; /* <=28 */
  box-sizing: border-box;
}

/* Custom
 * --------------------------------------- */
html,
body {
  font-family: "Poppins", "Rubik", sans-serif;
  height: 100%;
}
.header {
  padding-top: 40px;
  padding-bottom: 0px;
  font-size: 1.5em;
  vertical-align: -webkit-baseline-middle;
  vertical-align: middle;
  display: table-cell;
  color: rgb(0, 0, 0);
  /* color: rgb(51, 51, 51); */
}
.header span {
  font-size: 1.5em;
  color: #666;
  font-weight: bold;
}
.header h1 {
  font-size: 1.8em;
  color: #666;
  font-weight: bold;
}
.header br {
  font-size: 3em;
  color: #666;
  font-weight: bold;
}
.form_cadastro {
  height: 100%;
  vertical-align: -webkit-baseline-middle;
  vertical-align: middle;
  background-color: #fff;
  padding: 2% 5% 2% 5%;
  text-align: center;
  border-radius: 10px;
}
.direita {
  width: 95%;
  padding-left: 5%;
  padding-top: 10%;
  text-align: left;
}

h3 {
  font-size: 4em;
  /* text-align: center; */
  color: #666;
  font-weight: bold;
}
h2 {
  font-size: 2em;
  /* text-align: center; */
  color: #666;
  font-weight: bold;
}
#logo {
  position: fixed;
  top: 20px;
  left: 20px;
  color: #fff;
  font-weight: bold;
  z-index: 99;
  font-size: 1.9em;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
}

/* Menu
 * --------------------------------------- */
#menu-line {
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 159px;
  height: 2px;
  background: #fff;
}

#menu {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 70;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  letter-spacing: 1px;
  font-size: 1.1em;
}
#menu li {
  display: inline-block;
  margin: 10px 0px;
  position: relative;
}
#menu a {
  color: #fff;
  padding: 0 1.1em 1.1em 1.1em;
}
#menu li.active a:after {
  content: "";
  margin: 0 1.1em 0 1.1em;
  height: 2px;
  background: #fff;
  display: block;
  position: absolute;
  bottom: -6px;
  left: 0;
  right: 0;
  display: block;
}
.v-progress-circular {
  margin: 1rem;
}

.label {
  font-size: 10px;
}

.video {
    max-width: 560px;
    min-height: 300px;
    z-index: 2;
    border-radius: 5px;
}
@media only screen and (max-width: 1024px) {
  .video {
    min-height: 195px;
  }
}
</style>
