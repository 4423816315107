// import Http from './http'; // não precisa de .js
import axios from 'axios'

// const token = !localStorage.getItem('token')?false:localStorage.getItem('token')
const token = localStorage.getItem('token')
if(token)
  axios.defaults.headers.get['Authorization'] = 'Bearer ' + token
  axios.defaults.headers.get['Content-Type'] = 'application/json'

const config = {
  
  headers: {
    'Authorization': 'Bearer ' + token, 
    'Access-Control-Allow-Origin':'*'
  }
}

export const getCEP = (data) => {
  // console.log('data: ', data)
  // console.log('VUE_APP_CEP_API: ', process.env.VUE_APP_CEP_API)
  return axios.get(process.env.VUE_APP_CEP_API + '?cep=' + data, config)
}