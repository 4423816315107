export const formatPhone = (phone, ddi) => {
    let _phone = phone.replace(/\D/g, '')
    let initials = _phone.substring(0,2)
    if(initials === ddi) return _phone
    if(_phone.length >= 10 || _phone.length >= 11) {
        _phone = ddi.toString() + _phone.toString()
    } else {
        console.error('Telefone sem DDD: ', _phone)
    }

    return _phone

}

export const unformatPhone = (phone, ddi) => {
    let _phone = phone.replace(/\D/g, '')
    let initials = _phone.substring(0,2)
    if(initials === ddi) {
        let ddd = _phone.substring(2,4)
        let number_phone = _phone.substring(4, _phone.length)
        return String(ddd) + String(number_phone)
    } else {
        return _phone
    }
}

